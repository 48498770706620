import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette, spacing } = lightTheme;

export const propertyDetailCarouselDots = {
  position: 'absolute',
  bottom: 0,
  margin: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  width: '80%'
};

export const propertyDetailCarouselDotsItem = {
  // NOTE: !Important is added to over-ride alice-carousel-default-styles
  backgroundColor: `${palette.grey[50]} !important`,
  opacity: 0.5
};

export const propertyDetailCarouselDotsItemActive = {
  // NOTE: !Important is added to over-ride alice-carousel-default-styles
  backgroundColor: `${palette.grey[50]} !important`,
  opacity: 1
};

const styles: Record<string, SxProps> = {
  carouselContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  mainContainer: {
    width: '100%',
    height: '100%'
  },
  loadingBox: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 99,
    transform: 'translate(-50%, -50%)'
  },
  photoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    textAlign: 'center',
    backgroundColor: palette.grey[500],
    '& .loadingOverlay::after': {
      content: '""',
      height: '100%',
      width: '100%',
      zIndex: '50',
      backgroundColor: palette.grey.main
    },
    '& .carousel-photo': {
      filter: 'brightness(100%)',
      backgroundColor: palette.grey[500],
      '&.carousel-photo--loading': {
        filter: 'brightness(0%)'
      }
    },
    '& .photo': {
      width: '100%',
      // This height is being applied on following areas: map-popup-cards, newhomes-detail-page carousel
      // TODO: check value against org-value: '23.751rem'
      height: '36.751rem',
      objectFit: 'cover'
    },
    // This class applied properties to responsive-carousel on web property-detail-page-only
    '& img.photoPropertyDetail': {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    '& .fullscreenPhoto': {
      objectFit: 'contain',
      background: palette.grey[500],
      width: '100%',
      height: '100vh'
    },

    '.alice-carousel__dots': {
      position: 'absolute',
      bottom: 0,
      margin: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%'
    },

    '.alice-carousel__stage-item': {
      verticalAlign: 'middle',
      width: '100% !important'
    },

    '& .alice-carousel__next-btn, & .alice-carousel__prev-btn': {
      display: {
        xs: 'inline-flex',
        md: 'block'
      },
      cursor: 'pointer',
      position: 'absolute',
      height: {
        md: '8rem'
      },
      top: {
        xs: '50%',
        md: 'calc(50% - 3.5rem)'
      },
      width: 'auto',
      minWidth: '0',
      padding: '0.5rem',
      paddingY: {
        xs: '1.1rem',
        md: '3.5rem'
      },
      marginTop: '-1.375rem',
      background: palette.grey[500],
      opacity: '0.6',
      color: palette.grey['50'],
      fontWeight: 'bold',
      transition: '0.6s ease',
      border: 'none',
      '& > svg': {
        fontSize: {
          xs: '.9rem',
          md: '1.2rem'
        }
      }
    },
    '& .alice-carousel__next-btn': {
      right: '0'
    },
    '& .alice-carousel__prev-btn': {
      left: 0
    },
    // for property-list-cards
    '& .alice-carousel__next-btn_property-cards': {
      cursor: 'pointer',
      position: 'absolute',
      height: '1.75rem',
      borderRadius: '50%',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1.75rem',
      minWidth: '0',
      padding: '0.25rem',
      background: palette.grey[500],
      opacity: '0.5',
      color: palette.grey['50'],
      fontWeight: 'bold',
      transition: '0.6s ease',
      border: 'none',
      right: spacing(0.5),
      '& > svg': {
        fontSize: {
          xs: '.9rem',
          md: '1.2rem'
        }
      }
    },
    '& .alice-carousel__prev-btn_property-cards': {
      cursor: 'pointer',
      position: 'absolute',
      height: '1.75rem',
      borderRadius: '50%',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1.75rem',
      minWidth: '0',
      padding: '0.25rem',
      background: palette.grey[500],
      opacity: '0.5',
      color: palette.grey['50'],
      fontWeight: 'bold',
      transition: '0.6s ease',
      border: 'none',
      left: spacing(0.5),
      '& > svg': {
        fontSize: {
          xs: '.9rem',
          md: '1.2rem'
        }
      }
    }
  },
  iconButton: {
    position: 'absolute',
    top: {
      xs: spacing(0.5),
      md: spacing(2)
    },
    right: {
      xs: spacing(0.5),
      md: spacing(2)
    },
    zIndex: 2,
    background: palette.grey[500],
    opacity: 0.6,
    borderRadius: {
      xs: '0.25rem',
      md: '0'
    },
    '& > .MuiIconButton-root': {
      padding: {
        xs: spacing(0.5),
        md: '8px' // mui default padding
      }
    },
    '& svg': {
      color: palette.grey['50']
    }
  },
  counterContainer: {
    display: {
      xs: 'none',
      md: 'block'
    },
    position: 'absolute',
    bottom: spacing(2),
    right: spacing(2),
    zIndex: 1,
    background: palette.grey[50],
    padding: `${spacing(0.5)} ${spacing(0.25)}`,
    borderRadius: '0.5rem'
  },
  counter: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '0.7rem'
  },

  fullscreenWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'black',
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  isNewHomesCounterContainer: {
    display: 'block',
    bottom: {
      xs: '1rem',
      md: '2rem'
    },
    right: {
      xs: '1rem',
      md: '2rem'
    }
  },
  hideArrows: {
    display: {
      xs: 'none',
      md: 'block'
    }
  }
};

export default styles;
