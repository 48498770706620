import React from 'react';
import { SvgIcon } from '@mui/material';

function ArrowCollapse(props) {
  return (
    <SvgIcon {...props} title="ArrowCollapse" viewBox="0 0 24 24">
      <path d="M19.5,3.09L15,7.59V4H13V11H20V9H16.41L20.91,4.5L19.5,3.09M4,13V15H7.59L3.09,19.5L4.5,20.91L9,16.41V20H11V13H4Z" />
    </SvgIcon>
  );
}

export default ArrowCollapse;
